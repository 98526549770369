<template>
  <div>
    <nav class="fixed top-0 z-50 w-full h-14">
      <div class="bg-white border-b border-gray-200 shadow">
        <div class="flex flex-wrap items-center px-4 py-2">
          <div class="mr-4">
            <sa-icon-button
              with-bg
              iconClass="text-xl bx bx-menu"
              @click.stop="handleMenuClick"
            />
          </div>
          <div
            @click="$router.push({ to: '/home' })"
            class="flex flex-row flex-wrap items-center space-x-1 no-underline">
            <img src="@/assets/img/mini-responsive-samii.png" v-if="!logo" alt="" width="20px" class="m-1">
            <img :src="logo" v-if="logo" alt="" width="20px" class="m-1">
            <span class="font-bold text-gray-600 text-md font-headline">{{ $store.state.business.name || 'Fixme up' }}</span>
          </div>
          <div class="fixed right-4">
            <el-button
              class="text-gray-700 hover:text-purple-500"
              @click.stop="$router.push({ name: 'home.index' })">
              Atras
            </el-button>
          </div>
        </div>
      </div>
    </nav>
    <div class="min-h-screen bg-gray-50">
      <aside
        class="fixed h-screen border-r border-gray-200"
        :class="{ 'w-16': collapsed, 'w-64': !collapsed, 'disabled': disabled }">
        <nav>
          <div class="flex flex-col w-full text-gray-500">
            <div>
              <ul class="flex flex-col flex-grow py-6 space-y-1" :class="{ 'disabled': disabled }">
                <li id="sub-title-1" v-if="!collapsed && ($store.state.account.accountType === 1 || $store.state.account.canViewAdministration)">
                  <span
                    class="flex px-4 my-2 mt-4 text-sm font-bold text-gray-400 uppercase">
                    Administrar mi negocio
                  </span>
                </li>
                <li
                  class="flex-grow border-purple-500 cursor-pointer hover:bg-gray-100 hover:text-purple-500"
                  :class="{ 'border-l-4 bg-gray-100 border-purple-500 text-purple-500': isCurrentSlot(item.name) }"
                  v-for="item in myBusiness"
                  :key="item.name"
                  :id="item.name"
                  @click.stop="replaceSlot(item.name)">
                  <span
                    class="flex items-center p-2 space-x-2"
                    :class="collapsed ? 'justify-center' : ''">
                      <i
                        class="text-xl"
                        :class="item.iconClass">
                      </i>
                      <span v-if="!collapsed" class="font-semibold">{{ item.label }}</span>
                  </span>
                </li>
                <li id="sub-title-2" v-if="!collapsed">
                  <span class="flex px-4 my-2 mt-4 text-sm font-bold text-gray-400 uppercase">Administrar mi cuenta</span>
                </li>
                <li
                  class="flex-grow border-purple-500 cursor-pointer hover:bg-gray-100 hover:text-purple-500"
                  :class="isCurrentSlot(item.name) ? 'border-l-4 bg-gray-100 border-purple-500 text-purple-500': ''"
                  v-for="item in myAccount"
                  :key="item.name"
                  @click.stop="replaceSlot(item.name)">
                <span
                  class="flex items-center p-2 space-x-2"
                  :class="collapsed ? 'justify-center' : ''">
                    <i
                      class="text-xl"
                      :class="item.iconClass">
                    </i>
                    <span v-if="!collapsed" class="font-semibold">{{ item.label }}</span>
                </span>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </aside>
      <main class="mt-14" :class="collapsed ? 'ml-16' : 'ml-64'">
        <div class="px-8">
          <my-business
            v-if="slot === 'my-business'"
            :scrollToElementInSlot="scrollToElementInSlot" />
          <my-membership v-if="slot === 'my-membership'" />
          <users-and-permissions v-if="slot === 'users-and-permissions'" />
          <branch-offices v-if="slot === 'branch-offices'" />
          <notifications v-if="slot === 'notifications'" />
          <policies-and-templates
            v-if="slot === 'policies-and-templates'"
            :scrollToElementInSlot="scrollToElementInSlot" />
          <upload-client-data v-if="slot === 'upload-client-data'" />
          <upload-product-data v-if="slot === 'upload-product-data'" />
          <my-data v-if="slot === 'my-data'" />
          <security v-if="slot === 'security'" />
        </div>
      </main>
    </div>
  </div>
</template>
<style scoped>
.disabled:not(#my-membership) {
  cursor: not-allowed !important;
}
.disabled > li:not(#my-membership):not(#sub-title-1):not(#sub-title-2) {
  text-decoration: line-through;
}
.disabled:hover:not(#my-membership) {
  color: inherit;
  background-color: inherit;
}
</style>
<script>
import BusinessGetters from '@/store/modules/business/getters';
import responsiveSize from '@/mixins/responsiveSize';
import { Routes } from './constants';

export default {
  name: 'BusinessAdmin',
  mixins: [responsiveSize],
  components: {
    MyBusiness: () => import('@/views/app/admin/MyBusiness'),
    MyMembership: () => import('@/views/app/admin/MyMembership'),
    UsersAndPermissions: () => import(`@/views/app/admin/UsersAndPermissions`),
    BranchOffices: () => import('@/views/app/admin/BranchOffices'),
    Notifications: () => import('@/views/app/admin/Notifications'),
    PoliciesAndTemplates: () => import('@/views/app/admin/PoliciesAndTemplates'),
    MyData: () => import('@/views/app/admin/MyData'),
    Security: () => import('@/views/app/admin/Security'),
    UploadClientData: () => import('@/views/app/admin/UploadClientData.vue'),
    UploadProductData: () => import('@/views/app/admin/UploadProductData.vue'),
  },
  data() {
    let routes = [];
    const isOwner = this.$store.state.account.accountType === 1;
    const { canViewAdministration, canViewBranchOffices } = this.$store.state.account;

    if (isOwner || canViewAdministration) {
      routes = [
        Routes.business,
        Routes.membership,
        Routes.usersAndPermissions,
        Routes.branchOffices,
        Routes.notifications,
        Routes.policiesAndTemplates,
        Routes.uploadClientData,
        Routes.uploadProductData,
      ];
    } else if (canViewBranchOffices) {
      routes.push(Routes.branchOffices);
    }

    return {
      collapsed: false,
      slot: 'my-business',
      myBusiness: routes,
      myAccount: [Routes.data, Routes.security],
      scrollToElementInSlot: null,
    };
  },
  created() {
    window.addEventListener("resize", this.resize);
    this.resize();
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
  },
  mounted() {
    this.slot = this.$route.params.slot || Routes.business.name;
    if (this.$route.params.scrollToElementInSlot) {
      this.scrollToElementInSlot = this.$route.params.scrollToElementInSlot;
    }
  },
  computed: {
    logo() {
      return BusinessGetters.logo();
    },
    disabled() {
      return this.$store.state.account.membershipLimitReached;
    },
  },
  methods: {
    resize() {
      const percentageWidth = this.getPercentageWidth();
      this.collapsed = percentageWidth === '90%';
    },
    handleMenuClick() {
      this.collapsed = !this.collapsed;
    },
    replaceSlot(itemName) {
      if (this.disabled) return;
      this.slot = itemName;
    },
    isCurrentSlot(routePath) {
      return this.slot.includes(routePath);
    },
  },
}
</script>
