export const Routes = {
  business: { label: 'Mi negocio', name: 'my-business', iconClass: 'bx bx-info-circle'  },
  membership: { label: 'Mi membresía', name: 'my-membership', iconClass: 'bx bx-package' },
  usersAndPermissions: { label: 'Usuarios y permisos', name: 'users-and-permissions', iconClass: 'bx bxs-user-account' },
  branchOffices: { label: 'Sucursales', name: 'branch-offices', iconClass: 'bx bx-store' },
  notifications: { label: 'Notificaciones', name: 'notifications', iconClass: 'bx bxs-bell' },
  policiesAndTemplates: { label: 'Políticas y plantillas', name: 'policies-and-templates', iconClass: 'bx bx-receipt' },
  uploadClientData: { label: 'Importar Clientes', name: 'upload-client-data', iconClass: 'bx bx-cloud-upload' },
  uploadProductData: { label: 'Importar Productos', name: 'upload-product-data', iconClass: 'bx bx-cloud-upload' },
  data: { label: 'Mis datos', name: 'my-data', iconClass: 'bx bx-data' },
  security: { label: 'Seguridad', name: 'security', iconClass: 'bx bx-shield-quarter' },
};
